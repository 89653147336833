import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, children, style, className, variant, disabled, Icon, submit }) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: 'stroke-1 mr-2 -ml-1',
    primary: 'mr-2 -ml-1',
    icon: 'stroke-2'
  }
  const styles = {
    default: `text-base font-bold py-2 px-6 bg-blue text-white`,
    primary: `text-xl font-bold py-3 px-8 bg-blue text-white`,
    secondary: `text-xs font-bold py-2 px-5 bg-blue text-white`,
    icon: `h-8 w-8 font-light py-1 px-1 box-content bg-blue text-white`,
    smallIcon: `h-4 w-4 text-sm font-light py-1 px-1 box-content bg-blue-light text-blue`
  }
  const disabledStyle = 'opacity-50'
  return (
    <button
      type={submit && 'submit'}
      onClick={async () => {
        if (disabled) return 
        setLoading(true)
        try {
          await onClick()
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
        setLoading(false)
      }}
      style={{ ...style }}
      className={`${className} relative flex uppercase justify-center items-center align-middle overflow-hidden outline-none select-none flex-shrink-0 rounded-full ${styles[variant || 'default']} ${loading ? 'pointer-events-none' : 'pointer-events-auto'} ${disabled && disabledStyle}`}>
      <span className={`flex justify-center items-center align-middle ${loading && 'text-transparent'}`}>
        {Icon && <Icon className={iconStyles[variant || 'default']} />}
        {children}
      </span>
      {loading &&
        <div className={`absolute w-full h-full p-2 text-white`}>
          <FiLoader className="stroke-1 rotating box-border w-full h-full" />
        </div>
      }
    </button>
  )
}
