import moment from "moment"

const location = () => typeof window !== `undefined` ? window.location : { pathname: '', hostname: '', search: '' }

export const backUrl = (location().hostname === 'localhost') ? 'https://phoprint-back.wizito.com' : 'https://phoprint-back.wizito.com'
export const cardPaymentUrl = 'http://localhost:9000'
export const printerUrl = 'http://localhost:2000'
const logInterval = 4 * 60 * 60 * 1000
const boothId = (location().search.match(/id=(\d+)(&|$)/) || [])[1]
const boothToken = (location().search.match(/token=([^&]+)(&|$)/) || [])[1]
const authHeader = { headers: { 'Authorization': boothToken } }

export function findBooth() {
  return fetch(`${backUrl}/booths/${boothId}`, authHeader)
  .then(res => res.json())
  .then(res => res.error ? { error: `Echec de chargement de la configuration` } : res)
  .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export async function updateStatus() {
  const printer = await printerStatus()
  return fetch(`${backUrl}/booths/${boothId}`, {
    ...authHeader,
    method: 'PUT',
    body: JSON.stringify({ status: { connected: new Date(), printer } }) 
  })
}
if (boothToken) {
  // setInterval(updateStatus, logInterval)
  updateStatus()
}

export function findSession(id) {
  return fetch(`${backUrl}/sessions/${id}`, authHeader)
  .then(res => res.json())
  .then(res => res.error ? { error: `Aucune session n'a été trouvée` } : res)
  .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export function findCompany(id, token) {
  return fetch(`${backUrl}/companies/${id}?token=${token}`)
  .then(res => res.json())
  .then(res => res.error ? { error: `Aucune company n'a été trouvée` } : res)
  .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export function findSessionByCode(code) {
  return fetch(`${backUrl}/sessions/code/${code}`)
  .then(res => res.json())
  .then(res => res.error ? { error: `Aucune session n'a été trouvée` } : res)
  .catch(_ => ({ error: `Le serveur est inaccessible` }))
}

export function createSession() {
  return fetch(`${backUrl}/sessions/`, { method: 'POST', ...authHeader })
  .then(res => res.json())
}

export function uploadPhoto(code, photos, retryCount = 0) {
  console.log('Uploading', retryCount)
  const form = new FormData()
  photos.forEach(p => form.append('files.photos', p, 'photo.jpg'))
  form.append('data', JSON.stringify({}))
  return fetch(`${backUrl}/sessions/${code}`, { method: 'POST', body: form })
  .then(res => res.json())
  .catch(e => {
    console.log('Upload error', e)
    if (retryCount > 3) return Promise.resolve({ error: 'Erreur d\'upload' })
    return new Promise(resolve =>
      setTimeout(() =>
        resolve(
          uploadPhoto(code, photos, retryCount + 1)
        )
      , 1500)
    )
  })
}

export function applyPromo(id, promo) {
  return fetch(`${backUrl}/sessions/${id}/promo/${promo}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader.headers }
  })
  .then(res => res.json())
  .then(res => res.error ? { error: `Code de réduction invalide` } : res)
}

export function printerStatus() {
  return fetch(printerUrl).then(r => r.ok ? r.json() : ({ error: 'Erreur de communication avec l\'imprimante (peut être temporaire)' })).catch(e => ({ error: 'Server not found' }))
}

let lastAlert
let lastAlertMessage
export function printerAlert(config, error) {
  if (lastAlert && lastAlert.isAfter(moment().subtract(30, 'minutes')) || error === lastAlertMessage)
    return console.log('Avoid multi alert')
  if (error === 'Server not found')
    return console.log('Skip server not found')
  lastAlert = moment()
  lastAlertMessage = error
  if (!config.variables.alertEmails) return
  return fetch('https://europe-west1-wizito.cloudfunctions.net/cheerz-monop-alert', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: config.variables.alertEmails.join(','),
      body: `
      <h2>Erreur d'impression - ${config.name} </h2>
      Voici le statut d'erreur : ${error}
      <br/><br />
      <a href="https://www.youtube.com/watch?v=2oKODS3-6zM">Afficher la vidéo de changement de consommable</a>` })
  })
}

export function print(image64, quantity, media) {
  return fetch(`${printerUrl}/print`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ image: image64, quantity, media })
  })
}

export function pay(amount) {
  return fetch(`${cardPaymentUrl}/${amount}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  }).then(res => res.json())
}

export function createPayment(payment) {
  return fetch(`${backUrl}/payments`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader.headers },
    body: JSON.stringify(payment)
  }).then(res => res.json())
}

export function updatePayment(id, payment) {
  return fetch(`${backUrl}/payments/${id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...authHeader.headers },
    body: JSON.stringify(payment)
  }).then(res => res.json())
}

export function logSession(id, message, more) {
  return fetch(`${backUrl}/sessions/${id}/log`, { method: 'PUT', ...authHeader , body: JSON.stringify({ message, more }) })
}

export function auth(identifier, password) {
  return fetch(`${backUrl}/auth/local`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ identifier, password })
	}).then(res => res.json())
}